import React, { useState, useEffect, useRef } from "react";
import socketIOClient from "socket.io-client";
import Cookies from 'universal-cookie';

import format from 'date-fns/format';
import md5 from 'md5';

import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import WindIcon from '@material-ui/icons/Toys';
import DashboardIcon from '@material-ui/icons/Dashboard';
import WarningIcon from '@material-ui/icons/Warning';
import BarChartIcon from '@material-ui/icons/BarChart';
import SpeedIcon from '@material-ui/icons/Speed';
import PersonIcon from '@material-ui/icons/Person';
import RouterIcon from '@material-ui/icons/Router';
import LockIcon from '@material-ui/icons/Lock';
import LanguageIcon from '@material-ui/icons/Language';
import SettingsIcon from '@material-ui/icons/Settings';
import CheckIcon from '@material-ui/icons/Check';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Overview from './Overview';
import Details from './Details';
import Plotting from './Plotting';
import Errors from './Errors';
import Control from './Control';


import {dia_colors, STATUS_CODES} from './Conf';
import {ENDPOINT, CUSTOMER_AUTHORIZATION, MIN_TRACKER_ANGLE, MAX_TRACKER_ANGLE} from './ConfLocal.local';



 
// <From Dashboard template example>
const drawerWidth = 290;

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	loginRoot: {
		flexGrow: 1,
		height: '80vh',
		overflow: 'auto',
	},
	loginElements: {
		margin: theme.spacing(1),
	},
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    //height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
   fieldOrTrackerNo:{
	  fontWeight: 'bold',
	  fontSize: '130%',
  },
	angleInput: {
		width: 70,
	},
}));
// </From Dashboard template example>


const FieldOrTrackerListItem = withStyles({
  root: {
    borderLeft: '10px solid transparent',
	marginBottom: '1px',
  },	
  selected: {
	borderLeftColor: (props) => {
		if( props.color_index >= 0 && props.color_index < dia_colors.length )
			return dia_colors[props.color_index];
		else
			return 'transparent';
    },
  }
})(ListItem);


const ColoredStatusCode = withStyles({
  root: {
	backgroundColor: (props) => {
		if( STATUS_CODES[props.status_code] )
			return STATUS_CODES[props.status_code]['color'];
		else
			return 'transparent';
    },
	backgroundOpacity:.20,
	borderWidth: '1px',
	borderStyle: 'solid',
	borderRadius: '3px',
	borderColor: (props) => {
		if( STATUS_CODES[props.status_code] )
			return STATUS_CODES[props.status_code]['color'];
		else
			return 'grey';
    },
	paddingLeft: '2px',
	paddingRight: '2px',
	display: 'inline',
  }
})(Box);

const cookies = new Cookies();
const socket = socketIOClient(ENDPOINT, { autoConnect: true });

function dprint(msg)
{
	//console.log(msg);
}

function App() {

	// in order to establish a socket, we need either email + password or an existing session token for authentication
	//const [socket, setSocket] = useState(null);
	const [session, setSession] = useState(null);
	//const [session_id, setSessionId] = useState( cookies.get('session_id') );

	const [email, setEmail] = useState(null);
	const [password, setPassword] = useState(null);

	const [last_server_message, setLastServerMessage] = useState("");

	// the time keepers with their actual local PLC times, sent as strings without timezone, created here as date objects, so clients browser local timezone will be taken
	const [time_keepers, setTimeKeepers] = useState([]);
	// the headstation linux UTC time, sent as string without timezone, created here as date object with UTC timezone
	const [server_time, setServerTime] = useState(null);
	// date object for the time to display in the header, is either set to the server_time or to a time_keeper_time and, depending on display_time_source_plc_id it is either shown as browser local time or UTC time
	const [display_time, setDisplayTime] = useState(null);
	// for a time keeper plc_id > 0, for server UTC time = 0, to show server time in browsers local time = -1
	const [display_time_source_plc_id, setDisplayTimeSourcePlcId] = useState(0);
	// if display_time_source_plc_id > 0 we also need direct access to this time keeper
	const [selected_time_keeper, setSelectedTimeKeeper] = useState(null);

	// array of wind_zones (for the most projects with only 1 row)
	const [wind_zones, setWindZones] = useState([]);
	// the id of the default wind zone
	const [selected_wind_zone_id, setSelectedWindZoneId] = useState(1);
	// actual wind speed to be displayed (of the selected wind zone)
	const [wind_speed, setWindSpeed] = useState(null);

	// nested array of all fields with their trackers as sub-array is retrieved only one time when loading the page.
	// we can assume, that the number of fields/ trackers won't change during a visit - otherwise, the app just has to be reloaded
	// fields_with_trackers[] = { field_no, field_name, trackers[]=[tracker_no], plc_id, plc_ip, software_id }
	const [fields_with_trackers, setFieldsWithTrackers] = useState([]);

	// an associative array for field data from the server: fields_server_state[field_no] = { status_code, last_connection_time, last_connection_plc_time }
	const [fields_server_state, setFieldsServerState] = useState({});

	// an associative array for local state data on this client: fields_local_state[field_no] = { is_selected, ... }
	// we need let, not const, see setFieldOrTrackerState why
	let [fields_local_state, setFieldsLocalState] = useState({});
	
	// an associative array for tracker data from the server:
	// tracker_id = field_no+"_"+tracker_no
	// trackers_server_state[tracker_id] = { set_position, actual_position_n, actual_position_s, status_code }
	const [trackers_server_state, setTrackersServerState] = useState({});

	// an associative array for local state data on this client:
	// trackers_local_state[tracker_id] = { is_selected, color_index, ... }
	// we need let, not const, see setFieldOrTrackerState why
	let [trackers_local_state, setTrackersLocalState] = useState({});

	// park control
	const [do_park_request, setDoParkRequest] = useState(false); // == is_loading
	const [park_control_option, setParkControlOption] = useState(null);
	const [park_position, setParkPosition] = useState(null);
	const [str_park_position, setStrParkPosition] = useState("");


	function login()
	{
		if( !socket || !socket.id )
		{
			setLastServerMessage("Please reload the page (Press F5)");
			dprint("socket is missing - please reload");
			return;
		}

		if( !email || !password )
		{
			dprint("email or password is missing");
			return;
		}
		
		const md5_pwd = md5( password );
		dprint("logging in on socket "+socket.id+" for "+email);
		setLastServerMessage("");
		socket.emit("login",
		{
			email: email,
			md5_password: md5_pwd,
		});
	}
	function logout()
	{
		if( socket )
		{
			// the socket will be disconnected from server side, but we already remove the session cookie, to avoid a re-login
			cookies.remove('session_id');
			//setSessionId(null);
			setSession(null);
			socket.emit("logout");
			//setSocket(null); -> forces re-connection, but also hangs the browser
		}
	}



	useEffect(() =>
	{
		dprint("Using [] Effect");

		// fired on first connection or every re-connections
		socket.on("connect", () =>
		{
			const session_id = cookies.get('session_id');
			if( session_id )
			{			
				dprint("Trying to re-establish session with sessionId "+session_id);
				socket.emit("login",
				{
					session_id: session_id,
				});
			}

			// socket was disconnected from server side - most likely after logout event from client side		
			socket.on("disconnect", (msg) =>
			{
				//dprint("Setting session and socket null after disconnect:");
				dprint(msg);
				//setSession(null); // we don't wanna close the App aka show loggedOffContent just in case of a short Connection Loss. Brought this line to manual logout
				//setSocket(null); // and we try to re-connect via the integrated socketIOClient autoConnect option with integrating time interval
			});

			socket.on("server_message", data =>
			{
				if( data && data['msg'] )
				{
					console.log("Last Server Message: " +data['msg']);
					setLastServerMessage(data['msg']);
				}
			});

			socket.once("login", data =>
			{
				if( data && data['session_id'] )
				{
					dprint("Logged in with data:");
					dprint(data);
					setLastServerMessage("");
					setSession(data);
					cookies.set('session_id', data['session_id']);
					//setSessionId(cookies.get('session_id'));
				}
			});

			// Get a list with all fields state
			socket.once("get_fields_with_trackers", data =>
			{
				if( data )
				{
					dprint("getting inital fields with trackers array");
					setFieldsWithTrackers(data);
				}
			});

			// all times come from the headstation server whereas
			// time_keeper_plc_id > 0 returns the time from a time keeper plc
			// time_keeper_plc_id == 0 returns the linux UTC time from the headstation
			// and if session[time_keeper_plc_id] < 0, the servers UTC time is shown to the user in their local browser time time
			socket.on("get_time", data =>
			{
				if( data && data.length > 0 )
				{
					dprint("Getting time: "+data[0]['last_connection_plc_time']);
					let tmp_time_keepers = [];
					let t;
					for(let i = 0; i < data.length; i++)
					{
						// transform into a date object - either times from time keepers without any timezone information
						// or with plc_id === 0 as Date().toUTCString(), so it will be also parsed as UTC
						t = new Date(data[i]['last_connection_plc_time']);
						//m = moment.utc(data[i]['last_connection_plc_time']);

						// it is the linux headstation time
						if( data[i]['plc_id'] === 0 )
						{
							setServerTime(t);
						}
						else
						{
							data[i]['last_connection_plc_time'] = t;
							tmp_time_keepers.push(data[i]);
						}
					}
					setTimeKeepers(tmp_time_keepers);
				}
			});

			
			// We get the data of all wind zones and updating the wind speed
			// Wind speed will be automaticly updated by useEffect, whenever the windZones array or the selected_wind_zone_id changes
			socket.on("get_wind", data =>
			{
				if( data && data.length > 0 )
				{
					dprint("Getting wind zones:");
					dprint(data);
					setWindZones(data);
				}
			});

			// Get a list with all fields state
			socket.on("get_fields_state", data =>
			{
				if( data )
				{
					dprint("Getting fields state");
					setFieldsServerState(data);
				}
			});

			// Get a list with all fields + trackers and their status
			socket.on("get_trackers_state", data =>
			{
				if( data )
				{
					dprint("Getting trackers state");
					setTrackersServerState(data);
				}
			});
		});
		
		return () => {
			socket.off("server_message");
			socket.off("login");
			socket.off("get_fields_with_trackers");
			socket.off("get_time");
			socket.off("get_wind");			
			socket.off("get_fields_state");
			socket.off("get_trackers_state");
		}

	}, []);
	


	// server response
	useEffect(() =>
	{
		if( do_park_request && park_control_option )
		{
			socket.once("control_park", data =>
			{
				dprint("getting response ...");
				setParkControlOption(null);
				setDoParkRequest(false);
				if( data )
				{
					dprint(data);
				}
			});
		}

		// Clean up the effect - using socket.once() instead
		//return () => socket.off("control_trackers");
		
	}, [do_park_request, park_control_option]);
	// request
	useEffect(() =>
	{
		if( do_park_request )
		{
			if( park_control_option )
			{
				socket.emit("control_park",
				{
					position: park_position,
					control_option: park_control_option
				});
			}
			else
			{
				setDoParkRequest(false);
			}
		}
	}, [do_park_request, park_control_option, park_position]);



	// refresh the time
	useEffect(() =>
	{
		if( display_time_source_plc_id > 0 )
		{
			for(let i = 0; i < time_keepers.length; i++)
			{
				if( display_time_source_plc_id === time_keepers[i]['plc_id'] )
				{
					setDisplayTime(time_keepers[i]['last_connection_plc_time']);
					setSelectedTimeKeeper(time_keepers[i]);
					break;
				}
			}
		}
		else if( server_time )
		{
			setDisplayTime(server_time);
			setSelectedTimeKeeper(null);
		}

	}, [display_time_source_plc_id, server_time, time_keepers]);



	// refresh the wind speed, when wind zone is changed
	useEffect(() =>
	{
		for(let i = 0; i < wind_zones.length; i++)
		{
			if( selected_wind_zone_id === wind_zones[i]['wind_zone_id'] )
			{
				setWindSpeed(wind_zones[i]['actual_wind_speed']);
				break;
			}
		}
	}, [selected_wind_zone_id, wind_zones]);



	// this function returns an actual state of either a tracker (if tracker_no > 0) or a field and either from server state or from local. the keys to distinguish between server and local should be unique
	// advantage: we've to pass only this one function down with the nested react components and can get all the information we need everywhere and at any time
	function getFieldOrTrackerState(key, field_no, tracker_no = 0)
	{
		if( tracker_no > 0 )
		{
			const tracker_id = field_no+"_"+tracker_no;
			if( trackers_server_state[tracker_id] && typeof(trackers_server_state[tracker_id][key]) !== 'undefined' && trackers_server_state[tracker_id][key] != null)
				return trackers_server_state[tracker_id][key];

			else if( trackers_local_state[tracker_id] && typeof(trackers_local_state[tracker_id][key]) !== 'undefined' && trackers_local_state[tracker_id][key] != null)
				return trackers_local_state[tracker_id][key];
			
			else
				return null;
		}

		else
		{
			if( fields_server_state[field_no] && typeof(fields_server_state[field_no][key]) !== 'undefined' && fields_server_state[field_no][key] != null)
				return fields_server_state[field_no][key];

			else if( fields_local_state[field_no] && typeof(fields_local_state[field_no][key]) !== 'undefined' && fields_local_state[field_no][key] != null)
				return fields_local_state[field_no][key];		
			
			return null;
		}
	}
	// like the get function, here the set function
	// but this is only for changing the local states and we've to reset the whole associative array with the above defined setter functions
	function setFieldOrTrackerState(setMe, field_no, tracker_no = 0)
	{
		// change the state(s) of a tracker
		if( tracker_no > 0 )
		{
			const tracker_id = field_no+"_"+tracker_no;
			const new_trackers_local_state = {...trackers_local_state};
			if( !new_trackers_local_state[tracker_id] )
				new_trackers_local_state[tracker_id] = {};

			// setMe is an Object of {key1: val1, key2: val2, ...}
			for (const [key, val] of Object.entries(setMe))
			{
				new_trackers_local_state[tracker_id][key] = val;
			}
			
			// see fields_local_state
			trackers_local_state = new_trackers_local_state;
			setTrackersLocalState(new_trackers_local_state);
		}

		// change the state(s) of a field
		else
		{
			const new_fields_local_state = {...fields_local_state};

			if( !new_fields_local_state[field_no] )
				new_fields_local_state[field_no] = {};

			for (const [key, val] of Object.entries(setMe))
			{
				new_fields_local_state[field_no][key] = val;
			}

			// this is necessary, if we call the setter function multiple times e.g. in a loop, before auto re-rendering
			// therefore this variable needs to be defined with let, not with const
			fields_local_state = new_fields_local_state;
			setFieldsLocalState(fields_local_state => new_fields_local_state);
		}
	}

	function getSelectedFieldNos()
	{
		const selected_field_nos = [];
		for (const [field_no, field] of Object.entries(fields_local_state))
		{
			if( field.is_selected )
				selected_field_nos.push(field_no);
		}
		return selected_field_nos;
	}

	function getSelectedTrackerIds()
	{
		const selected_tracker_ids = [];
		for (const [tracker_id, tracker] of Object.entries(trackers_local_state))
		{
			if( tracker.is_selected )
				selected_tracker_ids.push(tracker_id);
		}
		return selected_tracker_ids;
	}


	// <From Dashboard template example>
	const classes = useStyles();
	const [navi_is_open, open_navi] = React.useState(true);
	const handleDrawerOpen = () => {
		open_navi(true);
	};
	const handleDrawerClose = () => {
		open_navi(false);
	};
	// </From Dashboard template example>



	// select a tracker with do_select=true, or deselect with do_select=false, or change selection: new_select = !old_select with do_select=null
	// we're also using this function to select fields (with tracker_no = 0)
	function selectFieldOrTracker(field_no, tracker_no = 0, do_select = null)
	{
		if( do_select !== true && do_select !== false )
			do_select = !getFieldOrTrackerState('is_selected', field_no, tracker_no );

		const new_color_index = do_select ? Math.floor(Math.random() * Math.floor(dia_colors.length)) : null;

		setFieldOrTrackerState(
			{
				is_selected: do_select,
				color_index: new_color_index
			},
			field_no,
			tracker_no
		);
	}

	// if all trackers of the field are selected, deselect all. otherwise select all
	// the event is fired in double click on a tracker. the status and the number of the firing tracker needs to be passed as argument, as it'll change cause of the preciding (single)
	function selectAllNeighbourTrackersInField(field, firing_tracker_no, firing_tracker_was_selected)
	{
		let do_select = false;
		let i;
		// if the firing tracker was selected, check if all others are also selected
		if( firing_tracker_was_selected )
		{
			for(i = 0; i < field.trackers.length; i++)
			{
				const tracker_no = field.trackers[i];
				// skip the firing tracker
				if( tracker_no === firing_tracker_no )
				{
					continue;
				}
				if( !getFieldOrTrackerState('is_selected', field.field_no, tracker_no ) )
				{
					do_select = true;
					break;
				}
			}
		}
		else
		{
			do_select = true;
		}

		for(i = 0; i < field.trackers.length; i++)
		{
			const tracker_no = field.trackers[i];
			selectFieldOrTracker(field.field_no, tracker_no, do_select);
		}
	}

	// like for a function component <FieldList /> it seems, that also {field_list} as variable re-renders with every update of tracker or field server state
	// but the re-rendering must be somehow more efficient, maybe re-rendered but DOM not always re-generated.
	// Cause when using {variables} instead of <FunctionComponents /> you could see the re-generation of the Navi-DOM on mouse over or with console.log
	// to make it even more efficient, we could use React.memo() at the point, for example to re-render only selected fields and not the whole field_list. But for the moment, we leave it the way it is...
	const field_list = fields_with_trackers.map( (field) =>
	{
		const field_no = field.field_no;
		const field_is_selected = !!getFieldOrTrackerState('is_selected', field_no);
		const field_color_index = getFieldOrTrackerState('color_index', field_no );
		const tracker_list = field_is_selected ? field.trackers.map( (tracker_no) =>
		{
			const tracker_id = field_no+"_"+tracker_no;
			const actual_position = getFieldOrTrackerState('actual_position_n', field_no, tracker_no );
			const status_code = getFieldOrTrackerState('status_code', field_no, tracker_no );
			const is_selected = !!getFieldOrTrackerState('is_selected', field_no, tracker_no );
			const color_index = getFieldOrTrackerState('color_index', field_no, tracker_no );
			
			return (
				<FieldOrTrackerListItem button key={tracker_id} selected={is_selected} onClick={() => selectFieldOrTracker(field_no, tracker_no)} onDoubleClick={() => selectAllNeighbourTrackersInField(field, tracker_no, is_selected)} color_index={color_index}>
					<ListItemIcon className={classes.fieldOrTrackerNo}>
						{tracker_no.toString().padStart(2,"0")}
					</ListItemIcon>						
					<ListItemText primary={ Number.isFinite(actual_position) ? actual_position.toFixed(1)+"°" : "—" } />
					<ListItemSecondaryAction>
						<StatusCode status_code={status_code} />
					</ListItemSecondaryAction>
				</FieldOrTrackerListItem>
			);
		}) : "";

		return (
			<List key={field_no} dense={true}>
				<FieldOrTrackerListItem button selected={field_is_selected} onClick={() => selectFieldOrTracker(field_no)} color_index={field_color_index}>
					<ListItemIcon className={classes.fieldOrTrackerNo}>
						{"F"+field.field_no.toString().padStart(2,"0")}
					</ListItemIcon>
					<ListItemText primary={field.field_name} />
					{field_is_selected ?  <ExpandMore /> : <ExpandLess />}
				</FieldOrTrackerListItem>
				{ field_is_selected ?
					(
						<List component="div" disablePadding dense={true}>
							{tracker_list}
						</List>
					) : ""
				}
			</List>
		);
	});




	function StatusCode(props){
		const status_code = props.status_code;
		const text = (props.text && props.text.length > 0) ? props.text : status_code;
		let tooltip = props.status_code;
		if( STATUS_CODES[status_code] )
			tooltip += ": "+STATUS_CODES[status_code]['description'];
		return (
			<Tooltip title={tooltip} placement="right">
				<ColoredStatusCode status_code={status_code}>{text}</ColoredStatusCode>
			</Tooltip>
		);
	}


	// should be used as variable {main_page} instead of a function <MainPage /> in order to avoid unnecessary re-renders
	const [current_page, setPage] = React.useState("Overview");
	let main_page;
	if( current_page === "Overview" && session && ( session.role === "admin" || ( session.role === "customer" && CUSTOMER_AUTHORIZATION['overview'] ) ) )
	{
		main_page = <Overview socket={socket} classes={classes} setPage={setPage} fields_with_trackers={fields_with_trackers} getFieldOrTrackerState={getFieldOrTrackerState} selectFieldOrTracker={selectFieldOrTracker} StatusCode={StatusCode} />;
	}
	else if( current_page === "Details" && session && ( session.role === "admin" || ( session.role === "customer" && CUSTOMER_AUTHORIZATION['details'] ) ) )
	{
		main_page = <Details socket={socket} classes={classes} getSelectedTrackerIds={getSelectedTrackerIds} getFieldOrTrackerState={getFieldOrTrackerState} StatusCode={StatusCode} />;
	}
	else if( current_page === "Plotting" && session && ( session.role === "admin" || ( session.role === "customer" && CUSTOMER_AUTHORIZATION['plotting'] ) ) )
	{
		main_page = <Plotting socket={socket} classes={classes} display_time={display_time} display_time_source_plc_id={display_time_source_plc_id} getSelectedFieldNos={getSelectedFieldNos} getSelectedTrackerIds={getSelectedTrackerIds} getFieldOrTrackerState={getFieldOrTrackerState} session={session} last_server_message={last_server_message} />;
	}
	else if( current_page === "Errors" && session && ( session.role === "admin" || ( session.role === "customer" && CUSTOMER_AUTHORIZATION['errors'] ) ) )
	{
		// Errors are logged and always shown with the local PLC time. That's why we forward the date object of the first time keeper to preset the pickers
		main_page = <Errors socket={socket} classes={classes} local_plc_time={time_keepers.length > 0 ? time_keepers[0]['last_connection_plc_time'] : null} getSelectedTrackerIds={getSelectedTrackerIds} getFieldOrTrackerState={getFieldOrTrackerState} trackers_local_state={trackers_local_state} />;
	}
	else if( current_page === "Control" && session && ( session.role === "admin" || ( session.role === "customer" && CUSTOMER_AUTHORIZATION['control'] ) ) )
	{
		main_page = <Control socket={socket} session={session} classes={classes} fields_with_trackers={fields_with_trackers} getSelectedTrackerIds={getSelectedTrackerIds} getFieldOrTrackerState={getFieldOrTrackerState} selectFieldOrTracker={selectFieldOrTracker} setFieldOrTrackerState={setFieldOrTrackerState} selected_time_keeper={selected_time_keeper} display_time={display_time} do_park_request={do_park_request} />;
	}
	// Overview page is selected (by default) but customer has no authorization to see it
	else
	{
		main_page = <div>No Authorization!</div>;
	}
	
	// Issue: when the Login Form got prefilled by the Browser setEmail() and setPassword() is not necessarily executed, cause later happens only onChange - but we need to have it 
	const loggedOffContent = (
		<Grid container direction="row" justify="center" alignItems="center" className={classes.loginRoot}>
			<Grid item xl={2} lg={3} md={4} sm={6} xs={10}>
			<form onSubmit={e => {e.preventDefault(); login();}}>
				<Paper className={classes.paper}>
					
					<img src="/SigmaView.png" alt="SigmaView" />
					{ ( socket ) ?
						<React.Fragment>
							<TextField label="Email" onChange={e => setEmail(e.target.value)} className={classes.loginElements} error={last_server_message.length > 0} />
							<TextField label="Password" onChange={e => setPassword(e.target.value)} type="password" className={classes.loginElements} error={last_server_message.length > 0} helperText={last_server_message} />
							<Button type="submit" className={classes.loginElements} variant="contained" color={last_server_message.length > 0 ? "secondary" : "primary"}	 disabled={!email || !password} >Login</Button>
						</React.Fragment>
						:
						<div>Please reload the page ...</div>
					}
				
				</Paper>
				</form>
			</Grid>
		</Grid>
	);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const topMenuOpen = (ref) => {
		//dprint(ref);
		setAnchorEl(ref.current);
	};
	const topMenuClose = () => {
		setAnchorEl(null);
	};
	const timeMenuIconRef = useRef();
	const windZoneMenuIconRef = useRef();
	const parkControlMenuIconRef = useRef();
	const userMenuIconRef = useRef();
	const loggedInContent = (
		<div className={classes.root}>

			<CssBaseline />


			<AppBar position="absolute" className={clsx(classes.appBar, navi_is_open && classes.appBarShift)}>

				<Toolbar className={classes.toolbar}>
					<IconButton
						edge="start"
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						className={clsx(classes.menuButton, navi_is_open && classes.menuButtonHidden)}
					>
						<MenuIcon />
					</IconButton>
					<Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
						{current_page}
					</Typography>


					<IconButton ref={timeMenuIconRef} color="inherit" onClick={() => topMenuOpen(timeMenuIconRef)}>
						<AccessTimeIcon />
					</IconButton>
					<Menu
						id="timeMenu"
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl===timeMenuIconRef.current)}
						onClose={topMenuClose}
					>
						<MenuItem
							onClick={() => {
								setDisplayTimeSourcePlcId(-1);
								topMenuClose();
								}
							}
							selected={display_time_source_plc_id === -1}
						>
							<ListItemIcon>
								<PersonIcon />
							</ListItemIcon>
							<ListItemText primary="My local time" secondary={server_time ? format(server_time, "HH:mm:ss") : "—"} />
						</MenuItem>

						<MenuItem
							onClick={() => {
								setDisplayTimeSourcePlcId(0);
								topMenuClose();
								}
							}
							selected={display_time_source_plc_id === 0}
						>
							<ListItemIcon>
								<LanguageIcon/>
							</ListItemIcon>
							<ListItemText primary="UTC time" secondary={server_time ? server_time.toISOString().substr(11,8) : "—"} />
						</MenuItem>

						<Divider />

						{
							time_keepers.map( (time_keeper) => 
							{
								return (
									<MenuItem
										key={"tk"+time_keeper['plc_id']}
										onClick={() => {
											setDisplayTimeSourcePlcId(time_keeper['plc_id']);
											topMenuClose();
											}
										}
										selected={display_time_source_plc_id === time_keeper['plc_id']}
									>
										<ListItemIcon>
											<RouterIcon />
										</ListItemIcon>
										<ListItemText primary={time_keeper['plc_ip']} secondary={time_keeper['last_connection_plc_time'] ? format(time_keeper['last_connection_plc_time'], "HH:mm:ss") : "—"} />
									</MenuItem>
								);
							})
						}
					</Menu>
					<Box>
						<Typography>
							{
								display_time_source_plc_id === 0 ?
									"UTC time"
									:
									display_time_source_plc_id > 0 && selected_time_keeper ?
										selected_time_keeper['plc_ip']
										:
										"My local time"
							}
						</Typography>					
						<Typography variant="body2">						
							{
								display_time ?
									display_time_source_plc_id === 0 ?
										display_time.toISOString().substr(11,8)
										:
										format(display_time, "HH:mm:ss")
									:
									"—"
							}
						</Typography>
					</Box>

					

					

					<IconButton ref={windZoneMenuIconRef} color="inherit" onClick={() => topMenuOpen(windZoneMenuIconRef)}>
						<WindIcon />
					</IconButton>
					<Menu
						id="windZoneMenu"
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl===windZoneMenuIconRef.current)}
						onClose={topMenuClose}
					>
						{
							wind_zones.map( (wind_zone) => 
							{
								return (
									<MenuItem
										key={"wz"+wind_zone['wind_zone_id']}
										onClick={() => {
											setSelectedWindZoneId(wind_zone['wind_zone_id']);
											topMenuClose();
											}
										}
										selected={selected_wind_zone_id === wind_zone['wind_zone_id']}
									>
										<ListItemIcon>
											<WindIcon />
										</ListItemIcon>
										<ListItemText primary={"WZ"+wind_zone['wind_zone_id']+" ("+wind_zone['plc_ip']+")"} secondary={Number.isFinite(wind_zone['actual_wind_speed']) ? wind_zone['actual_wind_speed'].toFixed(1)+" m/s" : "—"} />
									</MenuItem>
								);
							})
						}
					</Menu>
					<Box>
						<Typography>Wind speed</Typography>
						<Typography variant="body2">
						{
							Number.isFinite(wind_speed) ? 
								wind_speed.toFixed(1)+" m/s"
								:
								"—"
						}
						</Typography>
					</Box>
					


					{
						session && ( session.role === "admin" || ( session.role === "customer" && CUSTOMER_AUTHORIZATION['control/park'] ) ) ?
						(
							<React.Fragment>
								<IconButton ref={parkControlMenuIconRef} color="inherit" onClick={() => topMenuOpen(parkControlMenuIconRef)}>
									<SettingsIcon />
								</IconButton>
								<Menu
									id="parkControlMenu"
									anchorEl={anchorEl}
									keepMounted
									open={Boolean(anchorEl===parkControlMenuIconRef.current)}
									onClose={topMenuClose}
								>									
									<MenuItem>
										<Grid container justify="center">
											<Grid item xs={3} container justify="center" alignItems="center">
												<ListItemIcon>
													<CheckIcon />
												</ListItemIcon>
											</Grid>
											<Grid item xs={9} container alignItems="center">
												<Button
													variant="contained"
													color="primary"
													className={classes.button}
													disabled={do_park_request}
													onClick={() => {
															setParkControlOption("acknowledge");
														}
													}
												>
													Acknowledge Errors & Run
												</Button>
											</Grid>
										</Grid>
									</MenuItem>

									<MenuItem>
										<Grid container justify="center">
											<Grid item xs={3} container justify="center" alignItems="center">
												<ListItemIcon>
													<WarningIcon />
												</ListItemIcon>
											</Grid>
											<Grid item xs={9} container alignItems="center">
												<Button
													variant="contained"
													color="secondary"
													className={classes.button}
													disabled={do_park_request}
													onClick={() => {
															setParkControlOption("stop");
														}
													}
												>
													Emergency Stop
												</Button>
											</Grid>
										</Grid>
									</MenuItem>

									<MenuItem>
										<Grid container justify="center">
											<Grid item xs={3} container alignItems="center">
												<TextField
													id="standard-number"
													label="Angle"
													type="number"
													size="small"
													margin="none"
													className={classes.angleInput}
													onChange={(event) => setStrParkPosition(event.target.value)}
												/>
											</Grid>
											<Grid item xs={9} container alignItems="center">
												<Button
													variant="contained"
													color="default"
													className={classes.button}
													disabled={do_park_request}
													onClick={() => {
															if( str_park_position.length > 0 )
															{
																const angle = Number.parseFloat(str_park_position);
																if( Number.isFinite(angle) && angle >= MIN_TRACKER_ANGLE && angle <= MAX_TRACKER_ANGLE )
																{
																	setParkPosition(angle);
																	setParkControlOption("specific");
																}
															}
														}
													}
												>
													Go to Angle
												</Button>
											</Grid>
										</Grid>
									</MenuItem>

								</Menu>

								<Box>
									<Typography>Control</Typography>
									<Typography variant="body2">Park</Typography>
								</Box>
								

								<Dialog
									open={ park_control_option !== null && !do_park_request }
									onClose={() => {
										setParkControlOption(null);
										topMenuClose();
									}}
									aria-labelledby="alert-dialog-title"
									aria-describedby="alert-dialog-description"
								>
									<DialogTitle id="alert-dialog-title">{"Confirm Operation"}</DialogTitle>
									<DialogContent>
										<DialogContentText id="alert-dialog-description">
											Please confirm:
											You are going to
											{
												park_control_option === "specific" ?
												(<React.Fragment> send all trackers in the park to {park_position}°.</React.Fragment>)
												:
												(
													park_control_option === "acknowledge" ?
														(<React.Fragment> acknowledge all errors and restart all trackers in the park in auto mode.</React.Fragment>)
														:
														(<React.Fragment> stop all trackers in the park.</React.Fragment>)
												)
											}
										</DialogContentText>
									</DialogContent>
									<DialogActions>
										<Button onClick={() => {
												setParkControlOption(null);
												topMenuClose();
											}}
											color="primary"
										>
											Cancel
										</Button>
										<Button onClick={() => {
												setDoParkRequest(true);
												topMenuClose();
											}}
											color="primary"
										>
											Confirm
										</Button>
									</DialogActions>
								</Dialog>
								
							</React.Fragment>
						) : null
					}


					<IconButton ref={userMenuIconRef} color="inherit" onClick={() => topMenuOpen(userMenuIconRef)}>
						<PersonIcon />
					</IconButton>
					<Menu
						id="userMenu"
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl===userMenuIconRef.current)}
						onClose={topMenuClose}
					>
						<MenuItem onClick={logout}>
							<ListItemIcon>
								<LockIcon />
							</ListItemIcon>
							<Typography variant="inherit">Logout</Typography>
						</MenuItem>
					</Menu>
					<Box>
						<Typography>
						{
							session ? session.role.charAt(0).toUpperCase() + session.role.slice(1) : null
						}
						</Typography>
						<Typography variant="body2">
						{
							session ? session.email : null
						}
						</Typography>
					</Box>


				</Toolbar>

			</AppBar>


			<Drawer
				variant="permanent"
				classes={{
					paper: clsx(classes.drawerPaper, !navi_is_open && classes.drawerPaperClose),
				}}
				open={navi_is_open}
			>

				<div className={classes.toolbarIcon}>
					<img src="/SigmaView.png" alt="SigmaView" width={180} height={60} />
					<IconButton onClick={handleDrawerClose}>
						<ChevronLeftIcon />
					</IconButton>
				</div>

				<Divider />

				<List>
					{
						session && ( session.role === "admin" || ( session.role === "customer" && CUSTOMER_AUTHORIZATION['overview'] ) ) ?
							(
							<ListItem key={"nav_overview"} button selected={current_page==="Overview"} onClick={() => setPage("Overview")}>
								<ListItemIcon>
									<DashboardIcon />
								</ListItemIcon>
								<ListItemText primary="Overview" />
							</ListItem>
							) : null
					}
					{
						session && ( session.role === "admin" || ( session.role === "customer" && CUSTOMER_AUTHORIZATION['details'] ) ) ?
							(
							<ListItem key={"nav_details"} button selected={current_page==="Details"} onClick={() => setPage("Details")}>
								<ListItemIcon>
									<SpeedIcon />
								</ListItemIcon>
								<ListItemText primary="Details" />
							</ListItem>
							) : null
					}
					{
						session && ( session.role === "admin" || ( session.role === "customer" && CUSTOMER_AUTHORIZATION['plotting'] ) ) ?
							(
							<ListItem key={"nav_plotting"} button selected={current_page==="Plotting"} onClick={() => setPage("Plotting")}>
								<ListItemIcon>
									<BarChartIcon />
								</ListItemIcon>
								<ListItemText primary="Plotting" />
							</ListItem>
							) : null
					}
					{
						session && ( session.role === "admin" || ( session.role === "customer" && CUSTOMER_AUTHORIZATION['errors'] ) ) ?
							(
								<ListItem key={"nav_errors"} button selected={current_page==="Errors"} onClick={() => setPage("Errors")}>
									<ListItemIcon>
										<WarningIcon />
									</ListItemIcon>
									<ListItemText primary="Errors" />
								</ListItem>
							) : null
					}
					{
						session && ( session.role === "admin" || ( session.role === "customer" && CUSTOMER_AUTHORIZATION['control'] ) ) ?
							(
								<ListItem key={"nav_control"} button selected={current_page==="Control"} onClick={() => setPage("Control")}>
									<ListItemIcon>
										<SettingsIcon />
									</ListItemIcon>
									<ListItemText primary="Control" />
								</ListItem>
							) : null
					}
				</List>

				<Divider />

				<List>
					{field_list}
				</List>
			</Drawer>
			
			<main className={classes.content}>
				<div className={classes.appBarSpacer} />
				<Container maxWidth="lg" className={classes.container}>
				
					{main_page}

					<Box pt={4}>
						<Typography variant="body2" color="textSecondary" align="center">
							{'Copyright © '}
							<Link color="inherit" href="http://www.mounting-systems.com/">Mounting Systems GmbH</Link>{' '}
							{new Date().getFullYear()}
							{'.'}
						</Typography>
					</Box>
				</Container>
			</main>

		</div>
	);
	
	if( !session )
	{
		return loggedOffContent;
	}
	else
	{
		return loggedInContent;
	}
}

export default App;