import React, { useEffect, useState } from "react";

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';

import Box from '@material-ui/core/Box';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import ErrorTable from './ErrorTable';

const useStyles = makeStyles((theme) => ({
	formControl: {
		minWidth: 150,
	},
	labelShrink: {
		fontSize: "0.75rem",
	},
}));

function Errors( props )
{
	const classes = {...props.classes, ...useStyles()};
	
	const socket = props.socket;
	const local_plc_time = props.local_plc_time ? props.local_plc_time : new Date();
	const getSelectedTrackerIds = props.getSelectedTrackerIds;
	const trackers_local_state = props.trackers_local_state;

	// dates are set by the pickers/ date-fns in browser local time zone. we transfer it as string to the server without any time zone
	const [from_date, setFromDate] = useState(addDays(local_plc_time, -1));
	const [until_date, setUntilDate] = useState(local_plc_time);

	const [errors, setErrors] = useState([]);

	const [showErrors, setShowErrors] = useState("last_errors");
	const changeShowErrors = (event) => {
		setShowErrors(event.target.value);
	};

	// we've to seperate the hooks for receiving and sending data, cause otherwise the receiving part ist triggered multiple times
	useEffect(() =>
	{
		socket.on("get_errors", data =>
		{
			if( data )
			{
				for(let i = 0; i < data.length; i++)
				{
					if( data[i]['error_time'] )
						data[i]['error_time'] = new Date(data[i]['error_time']);
				}
				//console.log("new data:");
				//console.log(data);
				setErrors(data);
			}
		});
		
		// Clean up the effect
		return () => socket.off("get_errors");
		
	}, [socket]);

	// the sending hook has depends on a change of from and until data, as well as the trackers_local_state array, which tells us, that the selection has changed
	useEffect(() =>
	{
		if( showErrors === "by_date" && from_date instanceof Date && isFinite(from_date) && until_date instanceof Date && isFinite(until_date) )
		{
			socket.emit("get_errors",
			{
				// in mysql datetime format
				from_date: format(from_date, "yyyy-MM-dd HH:mm:ss"),
				until_date: format(until_date, "yyyy-MM-dd HH:mm:ss"),
				tracker_ids: getSelectedTrackerIds().length > 0 ? getSelectedTrackerIds() : null,
			});
		}
		else
		{
			socket.emit("get_errors",
			{
				max_rows: 100,
				tracker_ids: getSelectedTrackerIds().length > 0 ? getSelectedTrackerIds() : null,
			});
		}
	}, [socket, showErrors, from_date, until_date, trackers_local_state]);

	const num_of_selected_tackers = getSelectedTrackerIds().length;

	return (
	<MuiPickersUtilsProvider utils={DateFnsUtils}>
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Paper className={classes.paper}>
					<Grid container>

						<Grid item xs={4} container justify="flex-center" alignItems="flex-center">
							<FormControl className={classes.formControl}>
								<FormLabel className={classes.labelShrink}>
									Show Errors ...
								</FormLabel>
								<RadioGroup
									row
									defaultValue={showErrors}
									name="row-radio-buttons-group"
									onChange={changeShowErrors}
								>
									<FormControlLabel value="last_errors" control={<Radio />} label="Last 100" />
									<FormControlLabel value="by_date" control={<Radio />} label="By date range →" />
								</RadioGroup>
							</FormControl>
						</Grid>

						<Grid item xs={4} container justify="center" alignItems="flex-start">
							<KeyboardDateTimePicker
								variant="inline"
								ampm={false}
								label="From local PLC time"
								value={from_date}
								onChange={setFromDate}
								onError={console.log}
								format="yyyy/MM/dd HH:mm"
								disabled={showErrors!=="by_date"}
							/>
						</Grid>		

						<Grid item xs={4} container justify="flex-end" alignItems="flex-start">
							<KeyboardDateTimePicker
								variant="inline"
								ampm={false}
								label="Until local PLC time"
								value={until_date}
								onChange={setUntilDate}
								onError={console.log}
								format="yyyy/MM/dd HH:mm"
								disabled={showErrors!=="by_date"}
							/>
						</Grid>	
					</Grid>
					<Grid item xs={12}>
						<Box mt="2rem">Showing {errors.length} errors for {num_of_selected_tackers > 0 ? num_of_selected_tackers+" selected" : "all"} trackers ...</Box>
					</Grid>
				</Paper>
			</Grid>

			<Grid item xs={12}>
				<Paper className={classes.paper}>
					<ErrorTable errors={errors} />
				</Paper>
			</Grid>

		</Grid>
		
	</MuiPickersUtilsProvider>
	);
}

export default Errors;