export const dia_colors = ['#00429d', '#294f95', '#3a5d8e', '#456b87', '#4e7880', '#56867a', '#5e9374', '#65a16e', '#6eae68', '#78bb64', '#84c860', '#93d55e', '#a4e05e', '#baeb61', '#d6f56a', '#f7ed38', '#fada40', '#fbc846', '#fbb64b', '#f9a44e', '#f59350', '#f08150', '#ea6f51', '#e35e50', '#d94d4e', '#cf3b4c', '#c32a49', '#b51945', '#a50740', '#93003a'];

export const STATUS_CODE_OK = 211;
export const STATUS_CODES_FOR_CALIBRATION = [221, 201, 205];
export const STATUS_CODES =
{
	'0':	{'color': "rgba(255, 165, 0, 0.7)",		'description': "S1 in stop or manual (only PMS cabinet)"},
	'1':	{'color': "rgba(255, 0, 0, 0.7)",		'description': "PLC is not reachable"},
	'117':	{'color': "rgba(255, 0, 0, 0.7)",		'description': "First snow drop position; only one sensor active"},
	'118':	{'color': "rgba(255, 0, 0, 0.7)",		'description': "Second snow drop position; only one sensor active"},
	'201':	{'color': "rgba(255, 0, 0, 0.7)",		'description': "Tracker is not reachable"},
	'202':	{'color': "rgba(255, 0, 0, 0.7)",		'description': "Twist angle"},
	'203':	{'color': "rgba(255, 0, 0, 0.7)",		'description': "SigmaView emergency stop"},
	'204':	{'color': "rgba(255, 0, 0, 0.7)",		'description': "Tracker emergency stop (Q2)"},
	'205':	{'color': "rgba(255, 0, 0, 0.7)",		'description': "Frequency Converter Error"},
	'206':	{'color': "rgba(255, 165, 0, 0.7)",		'description': "Manual HHD control"},
	'207':	{'color': "rgba(255, 165, 0, 0.7)",		'description': "Acknowledgement is active"},
	'208':	{'color': "rgba(60, 179, 113, 0.7)",	'description': "On hold: max moving trackers reached"},
	'209':	{'color': "rgba(255, 165, 0, 0.7)",		'description': "Stopping before entering UPS mode"},
	'211':	{'color': "rgba(60, 179, 113, 0.7)",	'description': "Sun tracking"},
	'212':	{'color': "rgba(255, 165, 0, 0.7)",		'description': "Custom defined reaction (while S4=Auto)"},
	'213':	{'color': "rgba(255, 0, 0, 0.7)",		'description': "Wind sensor is damaged/ no communication with wind master (while S4=Auto)"},
	'214':	{'color': "rgba(255, 0, 0, 0.7)",		'description': "Tilt sensor is damaged (while S4=Auto)"},
	'215':	{'color': "rgba(255, 165, 0, 0.7)",		'description': "Troubleshoot mode (while S4=Auto)"},
	'216':	{'color': "rgba(255, 165, 0, 0.7)",		'description': "O&M team choosen angle (while S4=Auto)"},
	'217':	{'color': "rgba(255, 165, 0, 0.7)",		'description': "First snow drop position"},
	'218':	{'color': "rgba(255, 0, 0, 0.7)",		'description': "Second snow drop position"},
	'219':	{'color': "rgba(255, 0, 0, 0.7)",		'description': "Torque is higher than allowed (while S4=Auto)"},
	'221':	{'color': "rgba(255, 0, 0, 0.7)",		'description': "S4=Stop"},
	'231':	{'color': "rgba(255, 165, 0, 0.7)",		'description': "Go to maintenance angle (while S4=Maintenance)"},
	'232':	{'color': "rgba(255, 165, 0, 0.7)",		'description': "Custom defined reaction (while S4=Maintenance)"},
	'233':	{'color': "rgba(255, 0, 0, 0.7)",		'description': "Wind sensor is damaged/ no communication with wind master (while S4=Maintenance)"},
	'234':	{'color': "rgba(255, 0, 0, 0.7)",		'description': "Tilt sensor is damaged (while S4=Maintenance)"},
	'239':	{'color': "rgba(255, 0, 0, 0.7)",		'description': "Torque is higher than allowed (while S4=Maintenance)"},
	'241':	{'color': "rgba(255, 165, 0, 0.7)",		'description': "Go to Stow angle (while S4=Stow)"},
	'242':	{'color': "rgba(255, 165, 0, 0.7)",		'description': "Custom defined reaction (while S4=Stow)"},
	'243':	{'color': "rgba(255, 0, 0, 0.7)",		'description': "Wind sensor is damaged/ no communication with wind master (while S4=Stow)"},
	'244':	{'color': "rgba(255, 0, 0, 0.7)",		'description': "Tilt sensor is damaged (while S4=Stow)"},
	'249':	{'color': "rgba(255, 0, 0, 0.7)",		'description': "Torque is higher than allowed (while S4=Stow)"}
};