import React, { useEffect, useState } from "react";

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';	

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import ErrorTable from './ErrorTable';
import ProjectOverview from './ProjectOverview.local';

const useStyles = makeStyles((theme) => ({
	fieldsTable: {
		'& tbody': {
			'& tr': {
				cursor: 'pointer',
			},
		},
	},
}));


function Overview( props )
{
	const socket = props.socket;
	const classes = {...props.classes, ...useStyles()};
	const setPage = props.setPage;
	const fields_with_trackers = props.fields_with_trackers;
	const getFieldOrTrackerState = props.getFieldOrTrackerState;
	const selectFieldOrTracker = props.selectFieldOrTracker;
	const StatusCode = props.StatusCode;

	
	const [errors, setErrors] = useState([]);
	
	const [selected_field_no, selectFieldNo] = useState(0);
	
	// we've to seperate the hooks for receiving and sending data, cause otherwise the receiving part ist triggered multiple times
	useEffect(() =>
	{
		socket.on("get_errors", data =>
		{
			if( data )
			{
				for(let i = 0; i < data.length; i++)
				{
					if( data[i]['error_time'] )
						data[i]['error_time'] = new Date(data[i]['error_time']);
				}
				setErrors(data);
			}
		});
		
		// Clean up the effect
		return () => socket.off("get_errors");
		
	}, [socket]);

	// the sending hook has depends on a change of from and until data, as well as the trackers_local_state array, which tells us, that the selection has changed
	useEffect(() =>
	{
		socket.emit("get_errors",
		{
			max_rows: 5,
		});
	}, [socket]);


	function selectOneFieldOnly(field_no)
	{
		// select this field in the fields_table - its trackers will be shown
		selectFieldNo(field_no);
		
		// open this field in the left navi and close all others
		for(let i = 0; i < fields_with_trackers.length; i++)
		{
			const field = fields_with_trackers[i];
			const do_select = (field.field_no === field_no);
			selectFieldOrTracker(field.field_no, 0, do_select);
		}
	}


	function selectOneTrackerOnly(field_no, tracker_no)
	{
		for(let i = 0; i < fields_with_trackers.length; i++)
		{
			const field = fields_with_trackers[i];
			for(let j = 0; j < field.trackers.length; j++)
			{
				const do_select = (field.field_no === field_no && field.trackers[j] === tracker_no && !getFieldOrTrackerState('is_selected', field_no, tracker_no ));
				selectFieldOrTracker(field.field_no, field.trackers[j], do_select);
			}
		}
	}


	const fields_table = (
		<TableContainer>
			<Table className={classes.fieldsTable} size="small">
				<TableHead>
					<TableRow>
						<TableCell>No</TableCell>
						<TableCell align="center">Name</TableCell>
						<TableCell align="right">Trackers</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
				{
					fields_with_trackers.map( (field) =>
					{
						return (
							<TableRow hover={true} key={"tr_"+field.field_no} onClick={ () => selectOneFieldOnly(field.field_no) }>
								<TableCell>{"F"+field.field_no.toString().padStart(2,"0")}</TableCell>
								<TableCell align="center">{field.field_name}</TableCell>
								<TableCell align="right">{field.trackers.length}</TableCell>
							</TableRow>
						);
					})
				}
				</TableBody>
			</Table>
		</TableContainer>
	);


	function SelectedFieldWithTrackers()
	{
		let field = null;
		for( let i = 0; i < fields_with_trackers.length; i++ )
		{
			if( fields_with_trackers[i].field_no === selected_field_no )
			{
				field = fields_with_trackers[i];
				break;
			}
		}

		if( field !== null )
		{
			const field_no = field.field_no;
			return (
				<div>
					<List dense={true}>
						<ListItem key={"ov_nav_f"+field_no.toString()} button onClick={() => selectOneFieldOnly(0)}>
							<ListItemIcon>
								<ChevronLeftIcon />
							</ListItemIcon>
							<ListItemText primary={"F"+field_no.toString().padStart(2,"0")+" "+field.field_name} />
						</ListItem>
					</List>

					<TableContainer>
						<Table className={classes.fieldsTable} size="small">
							<TableHead>
								<TableRow>
									<TableCell>No</TableCell>
									<TableCell align="center">Set</TableCell>
									<TableCell align="center">Act</TableCell>
									<TableCell align="right">Sts</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
							{
								field.trackers.map( (tracker_no) =>
								{
									const set_position = getFieldOrTrackerState('set_position', field_no, tracker_no );
									const actual_position = getFieldOrTrackerState('actual_position_n', field_no, tracker_no );
									const status_code = getFieldOrTrackerState('status_code', field_no, tracker_no );
									const is_selected = !!getFieldOrTrackerState('is_selected', field_no, tracker_no );
									
									return (
										<TableRow selected={is_selected} hover={true} key={"tr_"+field_no+"_"+tracker_no} onClick={ () => selectOneTrackerOnly(field_no, tracker_no) }>
											<TableCell>{tracker_no.toString().padStart(2,"0")}</TableCell>
											<TableCell align="center">{ Number.isFinite(set_position) ? set_position.toFixed(1)+"°" : "—" }</TableCell>
											<TableCell align="center">{ Number.isFinite(actual_position) ? actual_position.toFixed(1)+"°" : "—" }</TableCell>
											<TableCell align="right"><StatusCode status_code={status_code} /></TableCell>
										</TableRow>
									);
								})
							}
							</TableBody>
						</Table>
					</TableContainer>

				</div>
			);
		}
		else
		{
			return  (
				<div>Error</div>
			);
		}
	}

	return (
		<Grid container spacing={3}>
	
			<Grid item xs={12} md={8} lg={8}>
				<Paper className={classes.paper}>
					<ProjectOverview classes={classes} fields_with_trackers={fields_with_trackers} getFieldOrTrackerState={getFieldOrTrackerState} selectOneFieldOnly={selectOneFieldOnly} StatusCode={StatusCode} />
				</Paper>
			</Grid>

			<Grid item xs={12} md={4} lg={4}>
				<Paper className={classes.paper}>
					{selected_field_no > 0 ? <SelectedFieldWithTrackers /> : fields_table}
				</Paper>
			</Grid>

			<Grid item xs={12}>
				<Paper className={classes.paper}>
					<Typography component="h2" variant="h6" color="primary" gutterBottom>Last Errors</Typography>
					<ErrorTable errors={errors} />
					<Box mt="1rem"><Link color="primary" href="#" onClick={() => setPage("Errors")}>Show more errors ...</Link></Box>
				</Paper>
			</Grid>

		</Grid>
	);
}

export default Overview;