//
// Template file for ProjectOverview.local.js with Project specific overview page
import React from "react";

import Box from '@material-ui/core/Box';

function ProjectOverview( props )
{
	let fields_with_position = [...props.fields_with_trackers];
	const getFieldOrTrackerState = props.getFieldOrTrackerState;
	const selectOneFieldOnly = props.selectOneFieldOnly;
	const StatusCode = props.StatusCode;

	// Position of field_no 01 Nyngan
	if( fields_with_position.length > 0 ){
		fields_with_position[0]['left'] = 580;
		fields_with_position[0]['top'] = 280;
	}

	// Position of field_no 02 Hermidale
	if( fields_with_position.length > 1 ){
		fields_with_position[1]['left'] = 60;
		fields_with_position[1]['top'] = 230;
	}

	return (
		<Box component="div" style={{position:'relative'}}>
			<img src="/Cobar.local.png" alt="Cobar Layout" />
			{
				fields_with_position.map( (field) =>
				{
					const status_code = getFieldOrTrackerState('status_code', field.field_no );
					return (
						<Box key={"fwp"+field.field_no} component="div" style={{position:'absolute', left:field.left+'px', top:field.top+'px', cursor: 'pointer'}} onClick={() => selectOneFieldOnly(field.field_no)}>
							<StatusCode status_code={status_code} text={ field.field_no.toString().padStart(2,"0") + field.field_name } />
						</Box>
					);
				})
			}
		</Box>
	);
}

export default ProjectOverview;
