import React, { useEffect, useState } from "react";

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';


import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import WarningIcon from '@material-ui/icons/Warning';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';
import RedoIcon from '@material-ui/icons/Redo';
import UndoIcon from '@material-ui/icons/Undo';
import SettingsIcon from '@material-ui/icons/Settings';
import CheckIcon from '@material-ui/icons/Check';
import BuildIcon from '@material-ui/icons/Build';
import SnowIcon from '@material-ui/icons/AcUnit';
import SunIcon from '@material-ui/icons/WbSunny';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import format from 'date-fns/format';

import {STATUS_CODES_FOR_CALIBRATION} from './Conf';
import {CUSTOMER_AUTHORIZATION, CUSTOM_TRACKER_GROUPS} from './ConfLocal.local';


export const useStyles = makeStyles((theme) => ({
	formControl: {
		minWidth: 150,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	angleInput: {
		width: 70,
	},
}));


function Control( props )
{
	const classes = {...props.classes, ...useStyles()};

	const socket = props.socket;
	const session = props.session;

	const fields_with_trackers = props.fields_with_trackers;
	const getSelectedTrackerIds = props.getSelectedTrackerIds;
	const getFieldOrTrackerState = props.getFieldOrTrackerState;
	const selected_time_keeper = props.selected_time_keeper;
	const setFieldOrTrackerState = props.setFieldOrTrackerState;
	const selectFieldOrTracker = props.selectFieldOrTracker;

	const selected_tracker_ids = getSelectedTrackerIds();
	

	const [do_tracker_request, setDoTrackerRequest] = useState(false); // == is_loading
	const [tracker_position, setTrackerPosition] = useState(null);

	const [do_field_request, setDoFieldRequest] = useState(false); // == is_loading
	const [field_control_option, setFieldControlOption] = useState(null);

	const [do_time_change, setDoTimeChange] = useState(false); // == is_loading
	const [time_keeper_time, setTimeKeeperTime] = useState(props.display_time);

	const [do_change_time_update_mode, setDoChangeTimeUpdateMode] = useState(false); // == is_loading

	const [do_calibration_states_request, setDoCalibrationStatesRequest] = useState(false); // == is_loading
	const [tracker_id_to_reset_calibration_status, setTrackerIdToResetCalibrationStatus] = useState(null); // also == is_loading

	const [do_snow_function_states_request, setDoSnowFunctionStatesRequest] = useState(false);
	const [set_snow_function, setSnowFunction] = useState(null);


	// server response
	useEffect(() =>
	{
		if( do_tracker_request )
		{
			socket.once("control_trackers", data =>
			{
				console.log("getting response ...");
				setDoTrackerRequest(false);
				if( data )
				{
					console.log(data);
				}
			});
		}

		if( do_field_request )
		{
			socket.once("control_field", data =>
			{
				console.log("getting response ...");
				setDoFieldRequest(false);
				if( data )
				{
					console.log(data);
				}
			});
		}

		if( do_time_change )
		{
			socket.once("change_time", data =>
			{
				console.log("getting response ...");
				setDoTimeChange(false);
				if( data )
				{
					console.log(data);
				}
			});
		}


		if( do_change_time_update_mode )
		{
			socket.once("change_time_update_mode", data =>
			{
				console.log("getting response ...");
				setDoChangeTimeUpdateMode(false);
				if( data )
				{
					console.log(data);
				}
			});
		}


		if( do_calibration_states_request )
		{
			socket.once("get_calibration_states", data =>
			{
				console.log("getting response ...");
				setDoCalibrationStatesRequest(false);
				if( data )
				{
					console.log(data);
					try
					{
						const resp_obj = JSON.parse(data);

						// check that the response is really for the field_no we requested initally
						const field_no = resp_obj['field_no'];
						if( field_no !== selected_field_nos[0] )
						{
							throw new Error("field_no does not match with the request");
						}
						for (const [status_key, status_value] of Object.entries(resp_obj.calibration_states))
						{
							// the status key is a string like "calibration_pending_23" and we wann get the 23 as tracker_no
							const tracker_no = status_key.substr( status_key.lastIndexOf("_")+1 );
							setFieldOrTrackerState(
								{
									calibration_status: status_value,
								},
								field_no,
								tracker_no
							);
						}
					}
					catch(ex)
					{
						console.log(ex.message);
					}
				}
			});
		}

		if( tracker_id_to_reset_calibration_status !== null )
		{
			socket.once("reset_calibration_status", data =>
			{
				console.log("getting response ...");
				setTrackerIdToResetCalibrationStatus(null);
				if( data )
				{
					console.log(data);
				}
			});
		}



		if( do_snow_function_states_request )
		{
			socket.once("get_snow_function_states", data =>
			{
				console.log("getting response ...");
				setDoSnowFunctionStatesRequest(false);
				if( data )
				{
					//console.log(data);
					try
					{
						const resp_obj = JSON.parse(data);
						console.log(resp_obj);
						resp_obj.map( (obj) => {
							setFieldOrTrackerState(
								{
									is_snow_function_enabled: obj.is_snow_function_enabled,
									is_snow_master: obj.is_snow_master,
								},
								obj.field.field_no
							);
						});
					}
					catch(ex)
					{
						console.log(ex.message);
					}
				}
			});
		}

		if( set_snow_function !== null )
		{
			socket.once("set_snow_function", data =>
			{
				console.log("getting response ...");
				setSnowFunction(null);
				if( data )
				{
					console.log(data);
				}
			});
		}



		// Clean up the effect
		return () =>
		{
			socket.off("control_trackers");
			socket.off("control_field");
			socket.off("change_time");
			socket.off("change_time_update_mode");
			socket.off("get_calibration_states");
			socket.off("reset_calibration_status");
			socket.off("get_snow_function_states");
			socket.off("set_snow_function");
		}
		
	}, [socket, do_tracker_request, do_field_request, do_time_change, do_change_time_update_mode, do_calibration_states_request, tracker_id_to_reset_calibration_status, do_snow_function_states_request, set_snow_function, ]);

	// request
	useEffect(() =>
	{
		if( do_tracker_request )
		{
			//moved to the server response
			//setDoTrackerRequest(false);
			
			const selected_tracker_ids = getSelectedTrackerIds();
			if( selected_tracker_ids.length > 0 )
			{
				socket.emit("control_trackers",
				{
					position: tracker_position,
					tracker_ids: selected_tracker_ids,
				});
			}
			else
				setDoTrackerRequest(false);
		}

		if( do_field_request )
		{			
			if( field_control_option && selected_field_nos.length === 1 )
			{
				socket.emit("control_field",
				{
					control_option: field_control_option,
					field_no: selected_field_nos[0],
				});
			}
			else
				setDoFieldRequest(false);
		}

		if( do_time_change )
		{			
			if( selected_time_keeper && time_keeper_time instanceof Date && isFinite(time_keeper_time) )
			{
				socket.emit("change_time",
				{
					time_keeper_plc_id: selected_time_keeper.plc_id,
					set_time: format(time_keeper_time, "yyyy-MM-dd HH:mm:ss"),
				});
			}
			else
				setDoTimeChange(false);
		}

		// 
		if( selected_time_keeper !== null && do_change_time_update_mode )
		{
			socket.emit("change_time_update_mode",
			{
				software_id: selected_time_keeper.software_id,
				set_local_time_automatically: !selected_time_keeper.set_local_time_automatically,
			});
		}

		// Get the calibration states of all trackers in the field
		if( do_calibration_states_request )
		{			
			if( selected_field_nos.length === 1 )
			{
				socket.emit("get_calibration_states",
				{
					field_no: selected_field_nos[0],
				});
			}
			else
				setDoCalibrationStatesRequest(false);
		}


		// Reset the status aka "calibration_pending_bit" for one single tracker. On control_field.control_option=="calibrate" this tracker will be recalibrated
		if( tracker_id_to_reset_calibration_status !== null )
		{
			socket.emit("reset_calibration_status",
			{
				tracker_id: tracker_id_to_reset_calibration_status,
			});
		}



		// Get the the states of the snow function from all PLCs
		if( do_snow_function_states_request )
		{
			socket.emit("get_snow_function_states");
		}



		if( set_snow_function !== null )
		{
			if( set_snow_function )
			{
				socket.emit("set_snow_function",
				{
					enable: true,
				});
			}
			else
			{
				socket.emit("set_snow_function",
				{
					disable: true,
				});
			}
		}


	}, [socket, do_tracker_request, do_field_request, do_time_change, do_change_time_update_mode, do_calibration_states_request, tracker_id_to_reset_calibration_status, selected_time_keeper, do_snow_function_states_request, set_snow_function ]);


	let selected_field_nos_by_key = {};
	let i;
	for( i = 0; i < selected_tracker_ids.length; i++ )
	{
		const field_and_tracker_no = selected_tracker_ids[i].split('_');
		selected_field_nos_by_key[field_and_tracker_no[0]] = true;
	}
	const selected_field_nos = Object.keys(selected_field_nos_by_key).map(Number);

	// some functions work only, if exactly one single field is selected
	let sole_selected_field = null;
	if( selected_field_nos.length === 1 )
	{
		for( i = 0; i < fields_with_trackers.length; i++ )
		{
			if( fields_with_trackers[i].field_no === selected_field_nos[0] )
			{
				sole_selected_field = fields_with_trackers[i];
				break;
			}
		}
	}

	// instead of checking only the field status, which is the "worst tracker status", we need to really make sure, that every single tracker of a field is in status == STATUS_CODES_FOR_CALIBRATION
	//const selected_field_status_code = (selected_field_nos.length === 1) ? getFieldOrTrackerState('status_code', selected_field_nos[0]) : null;
	let ready_for_calibration = false;
	if( !do_field_request && sole_selected_field !== null )
	{
		for( i = 0; i < sole_selected_field.trackers.length; i++ )
		{
			const tracker_status_code = getFieldOrTrackerState('status_code', sole_selected_field.field_no, sole_selected_field.trackers[i]);
			if( !STATUS_CODES_FOR_CALIBRATION.includes( tracker_status_code ) )
				break;
		}
		if( i === sole_selected_field.trackers.length )
			ready_for_calibration = true;
	}


	//
	// Functionality for selecting custom tracker groups
	const custom_tracker_group_keys = Object.keys(CUSTOM_TRACKER_GROUPS);
	const [selected_custom_tracker_group, setSelectedCustomTrackerGroup] = useState('');
	const selectCustomTrackerGroup = (event) =>
	{
		const custom_tracker_group = event.target.value;
		setSelectedCustomTrackerGroup(custom_tracker_group);

		// deselect all previous selected trackers
		for( i = 0; i < selected_tracker_ids.length; i++ )
		{
			const field_and_tracker_no = selected_tracker_ids[i].split('_');
			selectFieldOrTracker(field_and_tracker_no[0], field_and_tracker_no[1], false);
		}
		
		// select all trackers by id in the group
		if( CUSTOM_TRACKER_GROUPS[custom_tracker_group] )
		{
			for( i = 0; i < CUSTOM_TRACKER_GROUPS[custom_tracker_group].length; i++ )
			{
				const field_and_tracker_no = CUSTOM_TRACKER_GROUPS[custom_tracker_group][i].split('_');
				selectFieldOrTracker(field_and_tracker_no[0], field_and_tracker_no[1], true);
			}
		}
	};



	return (
	<MuiPickersUtilsProvider utils={DateFnsUtils}>
		<Grid container spacing={3}>

		{
			//
			// Select Custom Tracker Group
			( session && ( session.role === "admin" || ( session.role === "customer" && CUSTOMER_AUTHORIZATION['control/tracker'] ) ) && custom_tracker_group_keys.length > 0 ) ?
			(
				<Grid item xs={12}>
					<Paper className={classes.paper}>
						<Typography component="h2" variant="h6" color="primary" gutterBottom>
							Select Tracker Group
						</Typography>

						<Grid container>
					
							<Grid item xs={3}>

								<FormControl className={classes.formControl}>
									<InputLabel id="select_custom_tracker_group_label">
										Tracker Group
									</InputLabel>
									<Select
										labelId="select_custom_tracker_group_label"
										id="select_custom_tracker_group"
										value={selected_custom_tracker_group}
										onChange={selectCustomTrackerGroup}
										className={classes.selectEmpty}
									>
										<MenuItem value="">
											<em>None</em>
										</MenuItem>
										{
											custom_tracker_group_keys.map
											(
												(custom_tracker_group) =>
												(
													<MenuItem key={custom_tracker_group} value={custom_tracker_group}>{custom_tracker_group}</MenuItem>
												)
											)
										}
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			) : null
		}

		{
			//
			// Control Trackers
			( session && ( session.role === "admin" || ( session.role === "customer" && CUSTOMER_AUTHORIZATION['control/tracker'] ) ) ) ?
			(
				selected_tracker_ids.length < 1 ?
				(
					<Grid item xs={12}>
						<Paper className={classes.paper}>Select trackers from the left navigation menu in order to control them</Paper>
					</Grid>
				) :
				(
					<Grid item xs={12}>
						<Paper className={classes.paper}>
							<Typography component="h2" variant="h6" color="primary" gutterBottom>
								Control Trackers
							</Typography>
		
							<Grid container>
								<Grid item xs={12}>
									<Box mb="1rem">Change mode or position of {selected_tracker_ids.length} selected trackers to ...</Box>
								</Grid>

								<Grid item xs={2} container justify="flex-start" alignItems="center">
									<Button
										variant="contained"
										color="primary"
										className={classes.button}
										startIcon={<PlayArrowIcon />}
										onClick={() => {
												setTrackerPosition("auto");
												setDoTrackerRequest(true);
											}
										}
										disabled={do_tracker_request}
									>
										Auto
									</Button>
								</Grid>
								<Grid item xs={2} container justify="center" alignItems="center">
									<Button
										variant="contained"
										color="secondary"
										className={classes.button}
										startIcon={<StopIcon />}
										disabled={do_tracker_request}
										onClick={() => {
												setTrackerPosition("stop");
												setDoTrackerRequest(true);
											}
										}
									>
										Stop
									</Button>
								</Grid>
								<Grid item xs={2} container justify="center" alignItems="center">
									<Button
										variant="contained"
										color="default"
										className={classes.button}
										startIcon={<UndoIcon />}
										disabled={do_tracker_request}
										onClick={() => {
												setTrackerPosition("maint_west");
												setDoTrackerRequest(true);
											}
										}
									>
										West
									</Button>
								</Grid>
								<Grid item xs={2} container justify="center" alignItems="center">
									<Button
										variant="contained"
										color="default"
										className={classes.button}
										startIcon={<VerticalAlignTopIcon />}
										disabled={do_tracker_request}
										onClick={() => {
												setTrackerPosition("stow");
												setDoTrackerRequest(true);
											}
										}
									>
										Stow
									</Button>
								</Grid>
								<Grid item xs={2} container justify="center" alignItems="center">
									<Button
										variant="contained"
										color="default"
										className={classes.button}
										startIcon={<RedoIcon />}
										disabled={do_tracker_request}
										onClick={() => {
												setTrackerPosition("maint_east");
												setDoTrackerRequest(true);
											}
										}
									>
										East
									</Button>
								</Grid>
								<Grid item xs={2} container justify="flex-end" alignItems="center">
									<Box mr={3}>
										<TextField
											id="standard-number"
											label="Angle"
											type="number"
											size="small"
											margin="none"
											className={classes.angleInput}
											onChange={(event) => setTrackerPosition(parseFloat(event.target.value))}
										/>
									</Box>
									<Button
										variant="contained"
										color="default"
										className={classes.button}
										disabled={do_tracker_request}
										onClick={() => setDoTrackerRequest(true)}
									>
										Go
									</Button>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				)
			) :
			null
		}
		

		{
			//
			// Control Field
			( session && ( session.role === "admin" || ( session.role === "customer" && CUSTOMER_AUTHORIZATION['control/field'] ) ) ) ?
			(
				!sole_selected_field ?
				(
					<Grid item xs={12}>
						<Paper className={classes.paper}>In order to control one single field PLC, select at least one tracker of this field from the left navigation menu</Paper>
					</Grid>
				) :
				(
					<Grid item xs={12}>
						<Paper className={classes.paper}>
							<Typography component="h2" variant="h6" color="primary" gutterBottom>
								Control Field
							</Typography>
		
							<Grid container>
								<Grid item xs={12}>
									<Box mb="1rem">Control field {"F"+sole_selected_field.field_no.toString().padStart(2,"0")} ...</Box>
								</Grid>
								<Grid item xs={6} container justify="flex-start" alignItems="center">
									<Button
										variant="contained"
										color="primary"
										className={classes.button}
										startIcon={<CheckIcon />}
										disabled={do_field_request || ( session.role !== "admin" && !CUSTOMER_AUTHORIZATION['control/field'] )}
										onClick={() => {
												setFieldControlOption("acknowledge");
												setDoFieldRequest(true);
											}
										}
									>
										Acknowledge Errors & Run
									</Button>
								</Grid>
								<Grid item xs={6} container justify="flex-end" alignItems="center">
									<Button
										variant="contained"
										color="secondary"
										className={classes.button}
										startIcon={<WarningIcon />}
										disabled={do_field_request || ( session.role !== "admin" && !CUSTOMER_AUTHORIZATION['control/field'] )}
										onClick={() => {
												setFieldControlOption("stop");
												setDoFieldRequest(true);
											}
										}
									>
										Emergency Stop
									</Button>
								</Grid>

							</Grid>
						</Paper>
					</Grid>
				)
			) :
			null
		}


		{
			//
			// Change Time
			( session.role === "admin" || ( session.role === "customer" && CUSTOMER_AUTHORIZATION['control/time'] ) ) ?
			(
				!selected_time_keeper ?
				(
					<Grid item xs={12}>
						<Paper className={classes.paper}>In order to change the time, select the time keeper PLC at the top</Paper>
					</Grid>	
				) :
				(
					<Grid item xs={12}>
						<Paper className={classes.paper}>
							<Typography component="h2" variant="h6" color="primary" gutterBottom>
								Change Time
							</Typography>
		
							<Grid container>
								<Grid item xs={12}>
									<Box mb="1rem">Write a new time to the time keeper PLC {selected_time_keeper.plc_ip} ...</Box>
								</Grid>
								<Grid item xs={4} container justify="flex-start" alignItems="center">
									<FormControlLabel className={classes.formControl}
										control={
											<Switch
												checked={selected_time_keeper.set_local_time_automatically}
												onChange={() => {setDoChangeTimeUpdateMode(true);}}
												color="secondary"
											/>
										}
										labelPlacement="top"
										label={<InputLabel shrink>Update time automatically</InputLabel>}
									/>
								</Grid>
								<Grid item xs={4} container justify="center" alignItems="center">
									<KeyboardDateTimePicker
										variant="inline"
										ampm={false}
										/*disableFuture*/
										label={"New local PLC time"}
										value={time_keeper_time}
										onChange={setTimeKeeperTime}
										onError={console.log}
										format="yyyy/MM/dd HH:mm"
										disabled={selected_time_keeper.set_local_time_automatically}
									/>
								</Grid>
								<Grid item xs={4} container justify="flex-end" alignItems="center">
									<Button
										variant="contained"
										color="secondary"
										onClick={() => setDoTimeChange(true)}
										disabled={do_time_change || selected_time_keeper.set_local_time_automatically}
										startIcon={<SettingsIcon />}
									>
										Change
									</Button>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				)
			) :
			null
		}


		{
			//
			// Snow Function
			( session.role === "admin" || ( session.role === "customer" && CUSTOMER_AUTHORIZATION['control/snow'] ) ) ?
			( 
				<Grid item xs={12}>
					<Paper className={classes.paper}>
						<Typography component="h2" variant="h6" color="primary" gutterBottom>
							Snow Function
						</Typography>
	
						<Grid container>

							<Grid item xs={3} container justify="flex-start" alignItems="center">
								<Button
									variant="contained"
									color="primary"
									className={classes.button}
									startIcon={<SnowIcon />}
									disabled={do_snow_function_states_request || set_snow_function !== null || do_field_request}
									onClick={() => {
											setSnowFunction(true);
										}
									}
								>
									Enable Snow Function
								</Button>
							</Grid>
							<Grid item xs={3} container justify="flex-end" alignItems="center">
								<Button
									variant="contained"
									color="secondary"
									className={classes.button}
									startIcon={<SunIcon />}
									disabled={do_snow_function_states_request || set_snow_function !== null || do_field_request}
									onClick={() => {
											setSnowFunction(false);
										}
									}
								>
									Disable Snow Function
								</Button>
							</Grid>
						
							<Grid item xs={6} container justify="flex-end" alignItems="center">
								<Button
									variant="contained"
									color="default"
									className={classes.button}
									disabled={do_snow_function_states_request || set_snow_function !== null || do_field_request}
									onClick={() => {
											setDoSnowFunctionStatesRequest(true);
										}
									}
								>
									Check Snow Function Status
								</Button>
							</Grid>

							<Grid item xs={12}>
								<TableContainer>
									<Table size="small">
										<TableHead>
											<TableRow>
												<TableCell>Field No</TableCell>
												<TableCell align="center">Hierarchy</TableCell>
												<TableCell align="right">Snow Function Status</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
										{
											fields_with_trackers.map( (field) => 
											{
												const is_snow_function_enabled = getFieldOrTrackerState('is_snow_function_enabled', field.field_no);
												let snow_function_status = "unknown";
												if( is_snow_function_enabled === true )
													snow_function_status = "enabled";
												else if( is_snow_function_enabled === false )
													snow_function_status = "disabled";

												const is_snow_master = getFieldOrTrackerState('is_snow_master', field.field_no);
												let snow_hierarchy = "unknown";
												if( is_snow_master === true )
													snow_hierarchy = "master";
												else if( is_snow_master === false )
													snow_hierarchy = "slave";

												return (
													<TableRow hover={true} key={"f_"+field.field_no}>
														<TableCell>{field.field_no.toString().padStart(2,"0")}</TableCell>
														<TableCell align="center">{ snow_hierarchy }</TableCell>
														<TableCell align="right">{ snow_function_status }</TableCell>
													</TableRow>
												);
											})
										}
										</TableBody>
									</Table>
								</TableContainer>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			) :
			null
		}


		{
			//
			// Calibration
			( session.role === "admin" || ( session.role === "customer" && CUSTOMER_AUTHORIZATION['control/calibrate'] ) ) ?
			( 
				!sole_selected_field ?
				(
					<Grid item xs={12}>
						<Paper className={classes.paper}>In order to calibrate a field or check its calibration status, select at least one tracker of this field from the left navigation menu</Paper>
					</Grid>
				) :
				(
					<Grid item xs={12}>
						<Paper className={classes.paper}>
							<Typography component="h2" variant="h6" color="primary" gutterBottom>
								Calibrate
							</Typography>
		
							<Grid container>
								<Grid item xs={12}>
									<Box mb="1rem">Calibrate field {"F"+sole_selected_field.field_no.toString().padStart(2,"0")} ...</Box>
								</Grid>

								<Grid item xs={6} container justify="flex-start" alignItems="center">
									<Button
										variant="contained"
										color="default"
										className={classes.button}
										disabled={do_calibration_states_request || tracker_id_to_reset_calibration_status !== null || do_field_request}
										onClick={() => {
												setDoCalibrationStatesRequest(true);
											}
										}
									>
										Check Calibration
									</Button>
								</Grid>
								<Grid item xs={6} container justify="flex-end" alignItems="center">
									<Button
										variant="contained"
										color="default"
										className={classes.button}
										startIcon={<BuildIcon />}
										disabled={!ready_for_calibration}
										onClick={() => {
												setFieldControlOption("calibrate");
												setDoFieldRequest(true);
											}
										}
									>
										Calibrate
									</Button>
								</Grid>
								<Grid item xs={12}>
									<TableContainer>
										<Table size="small">
											<TableHead>
												<TableRow>
													<TableCell>Tracker No</TableCell>
													<TableCell align="center">Calibration Status</TableCell>
													<TableCell align="right">Reset</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
											{
												sole_selected_field.trackers.map( (tracker_no) =>
												{
													const calibration_status = getFieldOrTrackerState('calibration_status', sole_selected_field.field_no, tracker_no);
													let str_calibration_status = "unknown";
													if( calibration_status === true )
														str_calibration_status = "pending";
													else if( calibration_status === false )
														str_calibration_status = "calibrated";
													return (
														<TableRow hover={true} key={"tr_"+tracker_no}>
															<TableCell>{tracker_no.toString().padStart(2,"0")}</TableCell>
															<TableCell align="center">{ str_calibration_status }</TableCell>
															<TableCell align="right">
																<Button
																	disabled={calibration_status !== false || do_calibration_states_request || tracker_id_to_reset_calibration_status !== null || do_field_request}
																	onClick={() => {
																		setTrackerIdToResetCalibrationStatus(sole_selected_field.field_no+"_"+tracker_no)
																	}}
																	size="small"
																>
																	Reset
																</Button>
															</TableCell>
														</TableRow>
													);
												})
											}
											</TableBody>
										</Table>
									</TableContainer>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				)
			) :
			null
		}




		</Grid>

	</MuiPickersUtilsProvider>
	);
}

export default Control;