//
// Endpoint where to reach the backend
export const ENDPOINT = "http://"+window.location.hostname+":4001";

//
// per Project we can setup in ConfLocal.local.js which buttons are shown for the customer
// As this allowance check is only done on client side, it's not 100% save - but much faster to implement
// A clean future solution would be to setup user roles with allowed functionality per project in MySQL and check it on client as well as on server side.
export const CUSTOMER_AUTHORIZATION =
{
	'overview':			true,		// Show overview page
	'details':			true,		// Show details page
	'errors':			true,		// Show errors page
	'plotting':			true,		// Show plotting page
	'control':			false,		// Show control button in main navigation at all. Should be set true when any other entry is true
	'control/tracker':		false,		// Control trackers by stopping, reset to auto, sent to east, west, stow or specific angle
	'control/field':		false,		// Control field by (emergency) stop, acknowledge errors & run
	'control/park':			false,		// Control whole park by (emergency) stop, acknowledge errors & run, sent all trackers to certain angle
	'control/calibrate':		false,		// Calibrate all tilt sensors in a field
	'control/time':			false,		// Setting the time on time keeper
};

export const MIN_TRACKER_ANGLE = -55.0;
export const MAX_TRACKER_ANGLE = 55.0;

//
// For specific larger projects like Infinity50 we might want to have custom groups of tracker independet of the field. For example a whole column of trackers from North to South
// Key = name of the Group
// Value = Array with tracker_ids
//
// For specific larger projects like Infinity50 we might want to have custom groups of tracker independet of the field. For example a whole column of trackers from North to South
// Key = name of the Group
// Value = Array with tracker_ids
export const CUSTOM_TRACKER_GROUPS = {};
