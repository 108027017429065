import React from "react";

import format from 'date-fns/format';

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import Avatar from '@material-ui/core/Avatar';
import RouterIcon from '@material-ui/icons/Router';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';


import {STATUS_CODE_OK} from './Conf';


export const useStyles = makeStyles((theme) => ({
  horList: {
    display: 'flex',
  },
}));


function Details( props )
{
	const classes = {...props.classes, ...useStyles()};

	const StatusCode = props.StatusCode;

	const getSelectedTrackerIds = props.getSelectedTrackerIds;
	const getFieldOrTrackerState = props.getFieldOrTrackerState;

	const selected_tracker_ids = getSelectedTrackerIds();
	selected_tracker_ids.sort();
	const field_nos_of_selected_trackers = [];
	let last_field_no = 0;
	for(let i = 0; i < selected_tracker_ids.length; i++)
	{
		const field_and_tracker_no = selected_tracker_ids[i].split('_');
		const field_no = parseInt(field_and_tracker_no[0]);
		if( last_field_no !== field_no )
		{
			field_nos_of_selected_trackers.push( field_no );
			last_field_no = field_no;
		}
	}
	field_nos_of_selected_trackers.sort();

	const fields_details = field_nos_of_selected_trackers.map( (field_no) =>
	{
		const field_name = getFieldOrTrackerState('field_name', field_no);
		//const last_connection_time = new Date( getFieldOrTrackerState('last_connection_time', field_no) );
		const last_connection_plc_time = new Date( getFieldOrTrackerState('last_connection_plc_time', field_no) );
		const field_status_code = getFieldOrTrackerState('status_code', field_no);

		const trackers_details = selected_tracker_ids.map((tracker_id) =>
		{
			const field_and_tracker_no = tracker_id.split('_');
			const trackers_field_no = parseInt(field_and_tracker_no[0]);
			if( trackers_field_no !== field_no )
				return "";
			const tracker_no = parseInt(field_and_tracker_no[1]);
			const set_position = getFieldOrTrackerState('set_position', field_no, tracker_no);
			const actual_position_n = getFieldOrTrackerState('actual_position_n', field_no, tracker_no);
			const actual_position_s = getFieldOrTrackerState('actual_position_s', field_no, tracker_no);
			const status_code = getFieldOrTrackerState('status_code', field_no, tracker_no);
			return (
				<TableRow key={tracker_id}>
					<TableCell>{tracker_no.toString().padStart(2,"0")}</TableCell>
					<TableCell align="center">{Number.isFinite(set_position) ? set_position.toFixed(1)+"°" : "—"}</TableCell>
					<TableCell align="center">{Number.isFinite(actual_position_n) ? actual_position_n.toFixed(1)+"°" : "—"}</TableCell>
					<TableCell align="center">{Number.isFinite(actual_position_s) ? actual_position_s.toFixed(1)+"°" : "—"}</TableCell>
					<TableCell align="right"><StatusCode status_code={status_code} /></TableCell>
				</TableRow>
			);
		});

		return (
			<Grid key={field_no} item xs={12}>
				<Paper className={classes.paper}>
					<Typography component="h2" variant="h6" color="primary" gutterBottom>
						{field_no.toString().padStart(2,"0")} {field_name}
					</Typography>

					<List className={classes.horList}>
						<ListItem>
							<ListItemAvatar>
								<Avatar>
									<RouterIcon />
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary="IP Address" secondary={getFieldOrTrackerState('plc_ip', field_no)} />
						</ListItem>
						<ListItem>
							<ListItemAvatar>
								<Avatar>
									<AccessTimeIcon />
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary="PLC Time" secondary={isFinite(last_connection_plc_time) ? format(last_connection_plc_time,"yyyy/MM/dd HH:mm:ss") : "—"} />
						</ListItem>
						<ListItem>
							<ListItemAvatar>
								<Avatar>
								{
									field_status_code === STATUS_CODE_OK ?
									<CheckCircleIcon />
									:
									<WarningIcon />
								}
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary="Status Code" secondary=<StatusCode status_code={field_status_code} /> />
						</ListItem>
					</List>

					<TableContainer>
						<Table className={classes.table} aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell>Tracker</TableCell>
									<TableCell align="center">Set Position</TableCell>
									<TableCell align="center">Tilt Sensor North</TableCell>
									<TableCell align="center">Tilt Sensor South</TableCell>
									<TableCell align="right">Status Code</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{trackers_details}
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
			</Grid>
		);
	});


	return (
		<Grid container spacing={3}>
		{ field_nos_of_selected_trackers.length < 1 ?
			(
				<Grid item xs={12}>
					<Paper className={classes.paper}>Select trackers from the left navigation menu</Paper>
				</Grid>
			) :
			(
				fields_details
			)
		}
		</Grid>
	);
}

export default Details;