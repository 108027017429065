import React from "react";

import format from 'date-fns/format';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


function ErrorTable( props )
{
	const errors = props.errors;

	const error_list = errors.map( (error) =>
	{
		return (
			<TableRow key={error.error_id}>
				<TableCell>{error.field_no}</TableCell>
				<TableCell align="center">{error.tracker_no}</TableCell>
				<TableCell align="center">{error.error_code}</TableCell>
				<TableCell align="center">{Number.isFinite(error.position) ? error.position.toFixed(1)+"°" : "—" }</TableCell>
				<TableCell align="center">{Number.isFinite(error.wind_speed) ? error.wind_speed.toFixed(1)+" m/s" : "—"}</TableCell>
				<TableCell align="right">{isFinite(error.error_time) ? format(error.error_time,"yyyy/MM/dd HH:mm:ss") : "—"}</TableCell>
			</TableRow>
		);
	});


	return (
		<TableContainer>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Field</TableCell>
						<TableCell align="center">Tracker</TableCell>
						<TableCell align="center">Error code</TableCell>
						<TableCell align="center">Position</TableCell>
						<TableCell align="center">Wind speed</TableCell>
						<TableCell align="right">Local PLC time</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{error_list}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

function areEqual(prevProps, nextProps)
{
	//return true if passing nextProps to render would return
	//the same result as passing prevProps to render,
	//otherwise return false
	return (prevProps.errors === nextProps.errors);
}

export default React.memo(ErrorTable, areEqual);