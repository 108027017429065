import React, { useEffect, useState } from "react";

import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
//import MomentUtils from '@date-io/moment';
//import moment from 'moment-timezone';
import DateFnsUtils from '@date-io/date-fns';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
//import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';

import Dygraph from 'dygraphs';
import 'dygraphs/dist/dygraph.min.css'

import fileDownload from 'js-file-download'

import {dia_colors} from './Conf';

const useStyles = makeStyles((theme) => ({
	formControl: {
		minWidth: 150,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	dygraphDiv: {
		height: '600px',
		overflow: 'hidden',
	},
}));


function Plotting( props )
{
	const classes = {...props.classes, ...useStyles()};

	const socket = props.socket;
	const session = props.session;
	const display_time = props.display_time;
	const display_time_source_plc_id = props.display_time_source_plc_id;
	const getSelectedFieldNos = props.getSelectedFieldNos;
	const getSelectedTrackerIds = props.getSelectedTrackerIds;
	const getFieldOrTrackerState = props.getFieldOrTrackerState;
	const last_server_message = props.last_server_message;
	
	// As date-fns util shows always always in the browsers local timezone,
	// we create a new date object where we interprete UTC as local time for the case, that the user selected UTC in the header
	// otherwise we can just take the display time
	let init_pickers_time;
	if(display_time_source_plc_id === 0 && display_time)
	{
		const iso_datetime_string = display_time.toISOString();
		init_pickers_time = new Date(iso_datetime_string.substr(0, 10)+" "+iso_datetime_string.substr(11, 8));
	}
	else
	{
		init_pickers_time = display_time;
	}	
	const [from_date, setFromDate] = useState(addDays(init_pickers_time, -1));
	const [until_date, setUntilDate] = useState(init_pickers_time);


	let data_series =
	{
		set_angle:				"Set Position",
		north_sensor_angle:		"Tilt Sensor North",
		south_sensor_angle:		"Tilt Sensor South",
		actual_wind_speed:		"Wind Speed",
		status_number:			"Status Code",
		max_last_motor_torque:	"Torque",
	};
	
	if( session['role'] === "admin" )
	{
		data_series =
		{
			...data_series,
			torque:					"Actual Torque",
			ai_1_value:				"AI 1 Value",
			ai_2_value:				"AI 2 Value",
			ai_3_value:				"AI 3 Value",
			ai_4_value:				"AI 4 Value",
			average_active_power:	"Power",
			active_energy_import:	"Energy Consumption",
		};
	}


	// always set the first_parameter_name first. If second gets selected, but first is empty, select the first instead
	const [first_parameter_name, setFirstParameterName] = useState('');
	const [second_parameter_name, setSecondParameterName] = useState('');
	const selectFirstDataSeries = (event) =>
	{
		let val = event.target.value;
		if( !val && second_parameter_name )
		{
			val = second_parameter_name;
			setSecondParameterName('');
		}
		setFirstParameterName(val);
	};
	const selectSecondDataSeries = (event) =>
	{
		let val = event.target.value;
		if( !first_parameter_name )
		{
			setFirstParameterName(val);
			setSecondParameterName('');
		}
		else if( val !== first_parameter_name )
			setSecondParameterName(val);
	};

	const [separate_axes, setSeparateAxes] = useState(true);
	const changeSeparateAxes = () =>
	{
		setSeparateAxes( !separate_axes );
	}


	const [do_request, setDoRequest] = useState(false); // == is_loading	
	const [plotting_data_length, setPlottingDataLength] = useState(0);
	const [graph, setGraph] = useState(null);
	const [plotting_data, setPlottingData] = useState([]);
	const [plotting_data_labels, setPlottingDataLabels] = useState([]);

	useEffect(() =>
	{
		if( last_server_message === "done" )
		{
			socket.off("get_data_series");
			setDoRequest(false);
		}
	},[socket, last_server_message]);

	useEffect(() =>
	{
		// no plotting_data, remove the graph
		if( plotting_data_length < 1 )
		{
			return;
		}

		// we got data to plot ...
		// we received the first chunk of data... create the graph
		if( !graph )
		{
			const labels = plotting_data_labels;

			// transfer the data to it's corresponding y-axis (first or second)
			let series = {};
			for(let i = 1; i < labels.length; i++)
			{
				
				if( separate_axes && second_parameter_name && labels[i].startsWith(second_parameter_name) )
					series[labels[i]] = {axis: 'y2', strokePattern: [10, 2, 5, 2],};
				else
					series[labels[i]] = {axis: 'y1'};
				
				// extract tracker and field_no in order to get the color
				// the label for a parameter "abc_def" of tracker_no 22 in field_no 9: "abc_def_9_22"
				const _tracker_no = parseInt(labels[i].lastIndexOf('_'));
				const tracker_no = parseInt(labels[i].substring(_tracker_no+1));
				const _field_no = parseInt(labels[i].lastIndexOf('_', _tracker_no-1));
				const field_no = parseInt(labels[i].substring(_field_no+1, _tracker_no));
				const color_index = getFieldOrTrackerState('color_index', field_no, tracker_no );
				
				series[labels[i]]['color'] = dia_colors[color_index];
			}

			// transform dates into Date() Objects
			let x_label;
			if( display_time_source_plc_id < 0 )
				x_label = "My local time";
			else if( display_time_source_plc_id > 0 )
				x_label = "Time keepers local time";
			else
				x_label = "UTC time";

			const tmp_graph = new Dygraph
			(
				document.getElementById("dygraph"),
				plotting_data,
				{
					dateWindow: [from_date, until_date],
					labels: labels,
					width: -1,
					legend: 'follow',
					ylabel: first_parameter_name ? data_series[first_parameter_name] : null,
					y2label: second_parameter_name ? data_series[second_parameter_name] : null,
					xlabel: x_label,
					series: series,
					showRoller: true,
					rollPeriod: 1,
				}
			);

			setGraph(tmp_graph);
		}
		// the graph was already created, now update the data
		else
		{
			graph.updateOptions( { 'file': plotting_data } );
		}
	}, [plotting_data, graph, plotting_data_length]);

	// we've to separate the hooks for receiving and sending data, cause otherwise the receiving part ist triggered multiple times
	// but this effect needs to have the same dependency array in order to work with the latest state of the selected parameter_names
	// https://dygraphs.com/gallery/#g/dynamic-update
	useEffect(() =>
	{
		if( do_request )
		{
			socket.on("get_data_series", data =>
			{
				if( data && data.length > 0 )
				{
					// every incoming data package contains the labels as first row
					const labels = data.shift();
					// actually this is only necessary for the first run
					setPlottingDataLabels(labels);

					const maybe_add_UTC = (display_time_source_plc_id < 0) ? " UTC" : "";
					let tmp_plotting_data = plotting_data;
					for(let i = 0; i < data.length; i++)
					{
						data[i][0] = new Date(data[i][0]+maybe_add_UTC);
						tmp_plotting_data.push(data[i]);
					}
					setPlottingData(tmp_plotting_data);
					setPlottingDataLength(tmp_plotting_data.length);
				}
			});
		}
		
		// Clean up the effect
		return () => socket.off("get_data_series");
		
	}, [socket, do_request, plotting_data]);


	// the sending hook has depends on a change of from and until data, as well as the trackers_local_state array, which tells us, that the selection has changed
	useEffect(() =>
	{
		if( do_request )
		{
			// reset everything
			setGraph(null);
			document.getElementById("dygraph").innerHTML = "";
			setPlottingData([]);
			setPlottingDataLength(0);
			
			const selected_field_nos = getSelectedFieldNos();
			const selected_tracker_ids = getSelectedTrackerIds();
			if( from_date instanceof Date && isFinite(from_date) && until_date instanceof Date && isFinite(until_date) && first_parameter_name && ( selected_field_nos.length || selected_tracker_ids.length > 0 ) )
			{
				
				const parameter_names = [first_parameter_name];
				if(second_parameter_name)
					parameter_names.push(second_parameter_name);


				let from_date_str;
				let until_date_str;
				// if the user is selecting the time in their local browser timezone, we need to transform these times into and send them as UTC to the server
				if(display_time_source_plc_id < 0)
				{
					let iso_datetime_string = from_date.toISOString();
					from_date_str = iso_datetime_string.substr(0, 10)+" "+iso_datetime_string.substr(11, 8);
					iso_datetime_string = until_date.toISOString();
					until_date_str = iso_datetime_string.substr(0, 10)+" "+iso_datetime_string.substr(11, 8);
				}
				// otherwise we send directly the shown and selected time to the server where the times get interpreted either as UTC or time keeper local time
				else
				{
					from_date_str = format(from_date, "yyyy-MM-dd HH:mm:ss");
					until_date_str = format(until_date, "yyyy-MM-dd HH:mm:ss");
				}

				socket.emit("get_data_series",
				{
					parameter_names: parameter_names,
					from_date: from_date_str,
					until_date: until_date_str,
					display_time_source_plc_id: display_time_source_plc_id,
					field_nos: selected_field_nos,
					tracker_ids: selected_tracker_ids,
				});
			}
		}
	}, [socket, do_request]);

	const num_of_selected_tackers = getSelectedTrackerIds().length;
	const num_of_selected_fields = getSelectedFieldNos().length;
	const activate_show_button = ( ( num_of_selected_fields > 0 || num_of_selected_tackers > 0 ) && ( first_parameter_name.length > 0 || second_parameter_name.length > 0 ) && !do_request );

	// usually the date pickers use the browser local time zones, but the date object sent to the server is in UTC
	// this is ok, if the user decided to show everything in their local time.
	// but if they decided to show time keeper times (default) or UTC time, then we want a wysiwyg-aproach: exact the datetime shown to the user in the datepicker should be sent to the server
	//if( true )//if( session['timezone'] != "browser" )
		//moment.tz.setDefault("UTC");
	
	let _date_label;
	if( display_time_source_plc_id < 0 )
		_date_label = "my local time";
	else if( display_time_source_plc_id === 0 )
		_date_label = "UTC time";
	else
		_date_label = "time keeper local time";

	const download_csv = () =>
	{
		if( plotting_data_length < 1 )
			return;
		
		let csv_plotting_data = plotting_data_labels[0];
		let i, j;
		for(i = 1; i < plotting_data_labels.length; i++)
		{
			csv_plotting_data += ";"+plotting_data_labels[i];
		}
		csv_plotting_data += "\n";

		for(i = 0; i < plotting_data.length; i++)
		{
			// format the datetime to YYYY-MM-DD hh:mm:ss
			csv_plotting_data += plotting_data[i][0].getFullYear()+"-"+(plotting_data[i][0].getMonth()+1).toString().padStart(2,"0")+"-"+plotting_data[i][0].getDate().toString().padStart(2,"0")+" "+plotting_data[i][0].getHours().toString().padStart(2,"0")+":"+plotting_data[i][0].getMinutes().toString().padStart(2,"0")+":"+plotting_data[i][0].getSeconds().toString().padStart(2,"0");
			for(j = 1; j < plotting_data[i].length; j++)
			{
				csv_plotting_data += ";"+plotting_data[i][j];
			}
			csv_plotting_data += "\n";
		}
		
		fileDownload(csv_plotting_data, "plotting.csv");
	}
	
	return (
	<MuiPickersUtilsProvider utils={DateFnsUtils}>
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Paper className={classes.paper}>
					<Grid container>
					
						<Grid item xs={2} justify="flex-start" alignItems="flex-start">
							<FormControl className={classes.formControl}>
								<InputLabel shrink id="select_first_data_series_label">
									First data series
								</InputLabel>
								<Select
									labelId="select_first_data_series_label"
									id="select_first_data_series"
									value={first_parameter_name}
									onChange={selectFirstDataSeries}
									displayEmpty
									className={classes.selectEmpty}
								>
									<MenuItem value="">
										<em>None</em>
									</MenuItem>
									{
										Object.entries(data_series).map
										(
											([parameter_name, parameter_description]) =>
											(
												<MenuItem key={parameter_name+"_1"} value={parameter_name}>{parameter_description}</MenuItem>
											)
										)
									}
								</Select>
							</FormControl>
						</Grid>

						<Grid item xs={2} container justify="center" alignItems="flex-start">
							<FormControl className={classes.formControl}>
								<InputLabel shrink id="select_second_data_series_label">
									Second data series
								</InputLabel>
								<Select
									labelId="select_second_data_series_label"
									id="select_second_data_series"
									value={second_parameter_name}
									onChange={selectSecondDataSeries}
									displayEmpty
									className={classes.selectEmpty}
								>
									<MenuItem value="">
										<em>None</em>
									</MenuItem>
									{
										Object.entries(data_series).map
										(
											([parameter_name, parameter_description]) =>
											(
												<MenuItem key={parameter_name+"_1"} value={parameter_name}>{parameter_description}</MenuItem>
											)
										)
									}
								</Select>
							</FormControl>
						</Grid>

						<Grid item xs={1} container justify="center" alignItems="flex-start">
							<FormControlLabel className={classes.formControl}
								control={
									<Switch
										checked={separate_axes}
										onChange={changeSeparateAxes}
										color="primary"
										disabled={!second_parameter_name}
									/>
								}
								labelPlacement="top"
								label={<InputLabel shrink>Separate Y-axes</InputLabel>}
							/>
						</Grid>
				
						<Grid item xs={3} container justify="center" alignItems="flex-start">
							<KeyboardDateTimePicker
								variant="inline"
								ampm={false}
								/*disableFuture*/
								label={"From "+_date_label}
								value={from_date}
								onChange={setFromDate}
								onError={console.log}
								format="yyyy/MM/dd HH:mm"
							/>
						</Grid>		

						<Grid item xs={3} container justify="center" alignItems="flex-start">
							<KeyboardDateTimePicker
								variant="inline"
								ampm={false}
								/*disableFuture*/
								label={"Until "+_date_label}
								value={until_date}
								onChange={setUntilDate}
								onError={console.log}
								format="yyyy/MM/dd HH:mm"
							/>
						</Grid>
						<Grid item xs={1} container justify="flex-end" alignItems="center">
							<Button variant="contained" color="primary" onClick={() => setDoRequest(true)} disabled={!activate_show_button}>Show</Button>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Box mt="2rem">
						{
							do_request ?
								"Loading ["+last_server_message+"] ..."
								:
								plotting_data_length > 0 ?
									"Showing data series with "+plotting_data_length+" rows for "+num_of_selected_tackers+" selected trackers ..."
									:
									"No data series to plot. Select trackers from the left and data series from above and press the SHOW button"
						}
						</Box>
					</Grid>
				</Paper>
			</Grid>

			<Grid item xs={12}>
				<Paper className={classes.paper}>
					<Grid container>
						<Grid item xs={12}>
							<div id="dygraph" className={classes.dygraphDiv}></div>
						</Grid>
						<Grid item xs={12} container justify="flex-end" alignItems="center">
							<Button
								variant="contained"
								color="primary"
								className={classes.button}
								startIcon={<SystemUpdateAltIcon />}
								disabled={plotting_data_length < 1}
								onClick={() => download_csv()}
							>
								CSV
							</Button>
						</Grid>
					</Grid>	
				</Paper>
			</Grid>

		</Grid>
		
	</MuiPickersUtilsProvider>
	);
}

export default Plotting;